@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nasa';
  src: local('Nasa'), url(./fonts/Nasalization-rg.otf) format('opentype');
}

@font-face {
  font-family: 'Neuropol';
  src: local('Neuropol'), url(./fonts/Neuropol.otf) format('opentype');
}

/* reem-kufi-latin-wght-normal */
@font-face {
  font-family: 'Reem Kufi';
  font-style: normal;
  font-display: swap;
  font-weight: 400 700;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/reem-kufi:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* plus-jakarta-sans-latin-wght-normal */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 200 800;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/plus-jakarta-sans:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

body {
  margin: 0;
  font-family: Nasa;
  font-weight: 400;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* header */

.header-nav {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}

.header-nav.active {
  color: #0039A8;
}
.header-nav .login_button {
  font-size: 20px;
  color: red;
  animation: coloring 1.5s infinite;
}
@keyframes coloring {
  0% {
    color: red;
  }
  25% {
    color: orange;
  }
  50% {
    color: yellow;
  }
  75% {
    color: green;
  }
  100% {
    color: blue;
  }
}

.header-logo {
  margin-left: 60px;
  user-select: none;
  cursor: pointer;
}
/* white header */
.white-header .header-nav {
  font-family: 'Reem Kufi';
  font-size: 16px;
  color: white;
}

.white-header .header-nav:hover {
  color: #FFC10E;
}

/* home */
.home {
  padding-bottom: 110px;
}
#section1 {
  background-image: url(/public/img/home/tree.svg);
  background-repeat: no-repeat;
  height: 728px;
  display: flex;
}

#section1>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.frame1 {
  margin-left: auto;
  width: 684px;
}
.frame1 .title {
  font-family: Neuropol;
  font-size: 24px;

  color: #000000;
}

.frame1 .main-title {
  font-size: 72px;
  background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  margin-top: 12px;
}

.frame1 .content {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.65);

  margin-top: 50px;
}
/* temp change for booking a call
.frame1 .button1 {
  margin-top: 60px;
  border-radius: 12px;
  height: 120px;
  width: 640px;
  background-image: url(/public/img/home/button-pattern1.svg), linear-gradient(90deg, #283177 0%, #1F64A7 100%);
  background-size: 90px 95px, 100% 100%;
  background-position: left 550px top 25px, left 0 top 0;
  background-repeat: no-repeat, no-repeat;
  display: flex;
  align-items: center;
}
.frame1 .button1>div {
  color: white;
  font-size: 24px;
  margin-left: 80px;
}
*/

#section2 {
  margin-top: 70px;
  padding-bottom: 145px;
}

.frame2 {
  width: 1369px;
  padding-left: 24px;
  padding-right: 24px;
  margin-right: auto;
  margin-left: auto;
}
.frame2 .title {
  font-weight: 400;
  font-size: 30px;
  line-height: normal;
  background: linear-gradient(90deg, #090909 0%, #636464 100%, #282D74 100.01%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}
.frame2 .main-title {
  font-weight: 400;
  font-size: 72px;
  line-height: normal;
  background: linear-gradient(90deg, #090909 0%, #636464 100%, #282D74 100.01%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  margin-top: 15px;
}
.frame2 .content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 86px;
}
.frame2 .content .content-text {
  font-weight: 400;
  font-size: 30px;
  line-height: normal;
  color: rgba(0, 0, 0, 0.65);

  width: 740px;
}

#section3 {
  background-image: url(/public/img/home/bg-3-1.svg), url(/public/img/home/bg-3-2.svg), linear-gradient(180deg, #04559A 0%, #000B6E 100%);
  background-size: 459px 631px, 465px 517px, 100% 100%;
  background-position: left 0 top 215px, right 0 bottom 13px, 0 0;
  background-repeat: no-repeat, no-repeat;
  height: 1207px;
}

.frame3 {
  width: 1369px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
}

.frame3 .title {
  font-size: 30px;
  color: white;
}
.frame3 .main-title {
  font-size: 72px;
  color: white;
}

#section3 .group1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 44px;
}

#section3 .group-img img {
  height: 130px;
  margin-left: auto;
  margin-right: auto;
}

#section3 .group-text {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.85);

  width: 525px;
  margin-top: 24px;
  text-align: center;
}

#section4 {
  background-color: white;
  //height: 1502px;
  //padding-top: 403px;
  //padding-bottom: 219px;
  padding-top: 200px;
  padding-bottom: 100px;
}

#section4 .frame4 {
  width: 1289px;
  margin-left: auto;
  margin-right: auto;
}

.frame4 .title {
  font-size: 30px;
  background: linear-gradient(90deg, #090909 0%, #636464 100%, #282D74 100.01%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}
.frame4 .main-title {
  font-size: 72px;
  background: linear-gradient(90deg, #090909 0%, #636464 100%, #282D74 100.01%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  margin-top: 16px;
}

.frame4 .text-group {
  width: 668px;
  font-size: 30px;
  background: linear-gradient(90deg, #777 0%, #636464 100%, #000 100.01%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: 40px;
}

.frame4 .text-group ul {
  list-style-type: disc;
  color: #777;
}
.frame4 .img {
  width: 533px;
}

.frame4 .button1 {
  border-radius: 12px;
  height: 120px;
  width: 640px;
  background-image: url(/public/img/home/button-pattern1.svg), linear-gradient(90deg, #283177 0%, #1F64A7 100%);
  background-size: 90px 95px, 100% 100%;
  background-position: left 550px top 25px, left 0 top 0;
  background-repeat: no-repeat, no-repeat;
  display: flex;
  align-items: center;
}
.frame4 .button1>div {
  color: white;
  font-size: 24px;
  margin-left: 80px;
}

.frame4 .button2 {
  height: 120px;
  width: 640px;
  border-radius: 12px;
  border: 2px solid #283177;
  background: #FFF;
  background-image: url(/public/img/home/button-pattern2.svg);
  background-size: 90px 95px;
  background-position: left 550px top 25px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.frame4 .button2>div {
  color: #202751;
  font-size: 24px;
  margin-left: 80px;
}

/* fab button 
#sectionFab {
  background-color: blue;
}
#sectionFab .fab {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  width: 60px;
  height: 60px;
  background-color: #007bff; 
  color: white; 
  border-radius: 50%; 
  text-align: center;
  line-height: 60px; 
  font-size: 30px; 
  cursor: pointer; 
  z-index: 1000;
}
#sectionFab .modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
}

.modal .modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  width: 50%;
}

.modal .modal-content .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal .modal-content .close:hover,
.modal .modal-content .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
*/

/* News */
#sectionNews .main-title {
  background-image: url(/public/img/features/tree.svg);
  background-repeat: no-repeat;
  padding-top: 139px;
  padding-bottom: 89px;
  margin-left: auto;
  margin-right: auto;
  width: 1068px;
  font-size: 72px;
  text-align: center;
  background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1440px) {
  .news-page {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 835px) {
  #sectionNews .main-title {
    font-size: 46px;
    padding-top: 139px;
    padding-bottom: 89px;
  }
}

.news-main {
  width: 100%;
  background-image: url(/public/img/features/tree.svg);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
}

.news-page {
  display: grid;
  grid-gap: 20px;
  padding: 20px;
  margin: 50px auto 150px auto;
  max-width: 1200px;
  grid-template-columns: repeat(1, 1fr);
}

.tablet {
  display: none;
}

.mobile {
  display: none;
}

/* NewsSideBar */
.news-sidebar {
  width: 300px;
  margin-top: 120px;
  margin-left: 20px;
  padding: 20px;
  border-left: 1px solid #ccc;
  display: none;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(0,0,0,0.1);
}
.news-sidebar h2 {
  font-size: 20px;
  font-weight: 400;
  color: #1B5A9C;
}

@media (min-width: 1440px) {
  .news-sidebar {
      display: block;
  }
}

.news-sidebar-details {
  margin: 20px auto;
}

.news-sidebar-details img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}


/* NewsItem */
/* .news-item {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.news-item:hover {
  transform: translateY(-5px);
}

.news-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.news-item div {
  padding: 15px;
} */

@media (max-width: 1440px) {
  .news-page {
    grid-template-columns: repeat(1, 1fr);
  }
  .tablet {
    display: block;
  }
  .mobile {
    display: none;
  }
}

@media (max-width: 835px) {
  .desktop {
    display: none;
  }
  .tablet {
    display: none;
  }
  .mobile {
    display: block;
  }
  .news-page {
    margin-top: 0;
    grid-template-columns: 1fr;
  }
}

/* NewsDetail */
.news-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin: 50px auto 0 auto;
  max-width: 1000px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(0,0,0,0.1);
}

.news-detail h1 {
  font-size: 2.2rem;
  color: #333;
  text-align: center;
  width: 100%;
}

.news-detail h2 {
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  width: 100%;
}

.news-detail img {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 5px;
}

.news-detail video {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 5px;
}

.news-detail p {
  font-size: 1rem;
  line-height: 1.8;
  color: #666;
  width: 90%;  
  text-align: justify;
}

@media (min-width: 1441px) {
  .news-detail {
    padding: 40px;
    max-width: 1200px;
  }

  .news-detail h1 {
    font-size: 2.5rem;
  }

  .news-detail h2 {
    font-size: 1.5rem;
  }

  .news-detail p {
    width: 80%;
  }
} 

@media (max-width: 1440px) {
  .news-detail {
    padding: 30px;
    max-width: 1000px;
  }

  .news-detail h1 {
    font-size: 2.3rem;
  }

  .news-detail h2 {
    font-size: 1.5rem;
  }

  .news-detail p {
    width: 85%;
  }
}

@media (max-width: 835px) {
  .news-detail {
    padding: 20px;
    max-width: 600px;
  }

  .news-detail h1 {
    font-size: 2rem;
  }

  .news-detail h2 {
    font-size: 1.25rem;
  }

  .news-detail img {
    max-width: 600px;
  }

  .news-detail p {
    width: 100%;
  }
}

/* AI Native */
#section5 {
  background-image: url(/public/img/features/tree.svg);
  background-repeat: no-repeat;
}

#section5 .main-title {
  padding-top: 139px;
  padding-bottom: 89px;
  margin-left: auto;
  margin-right: auto;
  width: 1068px;
  font-size: 72px;
  text-align: center;
  background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#section5 .content {
  margin-top: 2px;
  width: 1369px;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-right: 140px;
}
#section5 .content .description {
  color: rgba(0, 0, 0, 0.65);
  font-size: 30px;
  width: 665px;
}
#section5 .content .img {
  width: 381px;
  height: 430px;
}

#section6 {
  background-image: url(/public/img/features/bg.svg);
  background-position: top 0px center;
  background-repeat: no-repeat;
  height: 2950px;
  margin-top: 73px;
  padding-top: 58px;
}

.frame6 {
  display: flex;
  align-items: center;
  width: 1369px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-right: 268px;
}
.frame6 .content {
  width: 800px;
  height: 488px;
  padding: 65px 68px 58px 67px;
  border-radius: 15px;
  background-color: white;
  background-image: url(/public/img/features/bg1.svg);
  background-position: top right;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
}
.frame6 .content .title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 48px;
}
.frame6 .content .text {
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 30px;
}
.frame6 .number1 {
  font-family: Neuropol;
  font-size: 450px;
  width: 108px;
  text-align: center;
  background: linear-gradient(180deg, #045299 0%, #000E70 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.frame7 {
  display: flex;
  align-items: center;
  width: 1369px;
  justify-content: space-between;
  margin-top: 90px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 42px;
}
.frame7 .content {
  border-radius: 15px;
  background-image: url(/public/img/features/bg2.svg), linear-gradient(180deg, #034993 0%, #010C6E 100%);
  background-size: auto;
  background-position: top center, 0 0;
  background-repeat: no-repeat;
  width: 800px;
  height: 488px;
  padding: 116px 68px;
}
.frame7 .content .title {
  color: rgba(255, 255, 255, 0.80);
  font-size: 48px;
}
.frame7 .content .text {
  color: rgba(255, 255, 255, 0.80);
  font-size: 30px;
  margin-top: 20px;
}
.frame7 .number2 {
  color: rgba(0, 0, 0, 0.70);
  -webkit-text-stroke-color: #000;
  font-family: Neuropol;
  font-size: 450px;
}

.frame8 {
  display: flex;
  align-items: center;
  width: 1369px;
  justify-content: space-between;
  margin-top: 189px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 68px;
}
.frame8 .content {
  width: 800px;
  height: 545px;
  padding: 116px 68px 65px 68px;
  border-radius: 15px;
  background-color: white;
  background-image: url(/public/img/features/bg3.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
}
.frame8 .content .title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 48px;
}
.frame8 .content .text {
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 30px;
}
.frame8 .number3 {
  font-family: Neuropol;
  font-size: 450px;
  width: 441px;
  text-align: center;
  background: linear-gradient(180deg, #034691 0%, #282D75 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.frame9 {
  display: flex;
  align-items: center;
  width: 1369px;
  justify-content: space-between;
  margin-top: 219px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 42px;
}
.frame9 .content {
  border-radius: 15px;
  background-image: url(/public/img/features/bg4.svg), linear-gradient(180deg, #034993 0%, #010C6E 100%);
  background-size: auto;
  background-position: bottom center, 0 0;
  background-repeat: no-repeat;
  width: 800px;
  height: 488px;
  padding: 83px 68px;
}
.frame9 .content .title {
  color: rgba(255, 255, 255, 0.80);
  font-size: 48px;
}
.frame9 .content .text {
  color: rgba(255, 255, 255, 0.80);
  font-size: 30px;
  margin-top: 20px;
}
.frame9 .number4 {
  color: rgba(0, 0, 0, 0.70);
  -webkit-text-stroke-color: #000;
  font-family: Neuropol;
  font-size: 450px;
}

/* industry */
/* .industry {
  padding-bottom: 110px;
} */
#section7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 149px;
}
#section7 .title {
  width: 713px;
  font-size: 72px;
  text-align: center;
  background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#section7 .description {
  color: rgba(0, 0, 0, 0.65);
  font-size: 40px;
}
#section7 .card-group {
  margin-top: 92px;
  display: grid;
  column-gap: 20px;
  row-gap: 25px;
  grid-template-columns: auto auto;
  margin-bottom: 112px;
}
#section7 .card-group .card {
  border-radius: 27px;
  background: linear-gradient(180deg, #034993 0%, #010C6E 100%);
  width: 653px;
  height: 197px;
  position: relative;
  padding: 25px 70px;
}
#section7 .card-group .card .open-quote {
  position: absolute;
  top: 16px;
  left: 27px;
  width: 26px;
  height: 17px;
}
#section7 .card-group .card .close-quote {
  position: absolute;
  top: 16px;
  right: 27px;
  width: 26px;
  height: 17px;
}
#section7 .card-group .card .text {
  font-family: Neuropol;
  font-size: 22px;
  color: #FFF;
  line-height: 127.778%;
  height: 67px;
  display: flex;
  align-items: center;
}
#section7 .card-group .card .user {
  margin-top: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#section7 .card-group .card .user .avatar {
  width: 58px;
  height: 58px;
  margin-right: 9px;
}

#section7 .card-group .card .user .intro {
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
  line-height: 127.778%;
  color: #FFF;
  min-width: 360px;
}
#section7 .video {
  margin-top: 20px;
  margin-bottom: 150px;
  width: 1135px;
  height: auto;
  border: 1px solid #0EC5FF;
}

#section7 .content {
  padding-top: 60px;
  width: 1200px;
  height: 300px;
  background-image: url(/public/img/industry/tree.svg);
  background-size: 856px;
  background-position: center;
  background-repeat: no-repeat;
}
#section7 .content .text1 {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 30px;
}
#section7 .content .text2 {
  margin: 50px auto 50px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 36px;
}

#section7 .carousel-container {
  overflow: hidden;
  max-width: 100%;
}

#section7 .carousel-container .carousel {
  margin-top: 20px;
  display: flex;
  align-items: center;
  min-width: 400%;
  animation: scroll 120s linear infinite;
}

#section7 .carousel-container .carousel .carousel-item {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

#section7 .carousel-container .carousel .carousel-item img {
  width: 300px;
  max-height: 200px;
  margin: 0 50px;
}

#section7 .carousel-container .carousel .desktop {
  display: block;
}
#section7 .carousel-container .carousel .mobile {
  display: none;
}

@media (max-width: 835px) {
  #section7 .carousel-container .carousel .desktop {
    display: none;
  }
  #section7 .carousel-container .carousel .mobile {
    display: block;
  }

  #section7 .carousel-container .carousel {
    min-width: 200%;
  }

  #section7 .carousel-container .carousel .carousel-item img {
    width: 150px;
    max-height: 100px;
    margin: 0 10px;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

#section7 .contentCounter {
  padding-top: 30px;
  width: 1200px;
  height: 250px;
}
#section7 .contentCounter .text {
  margin: 10px auto;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 48px;
}
#section7 .contentCounter .counterShow {
  margin: 20px auto;
  color: #1B5A9C;
  text-align: center;
  font-size: 56px;
}

@media (max-width: 835px) {
  #section7 .contentCounter {
    width: calc(100vw - 30px);
  }
  #section7 .contentCounter .text {
    font-size: 28px;
  }
  #section7 .contentCounter .counterShow {
    font-size: 40px;
  }
}

#section7 .company {
  margin-top: 30px;
  height: 200px;
}
#section7 .company .company-logos {
  width: 100%;
  margin: 30px auto;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  white-space: nowrap;
}
#section7 .company .company-logos img {
  height: 50px; 
  width: 240px;
  margin: 0 100px; 
  //flex: 0 0 auto;
}


/* contactus */
.contactus {
  background-image: url(/public/img/contactus/tree1.svg), url(/public/img/contactus/tree2.svg);
  background-position: left top, bottom right;
  background-repeat: no-repeat;
}
#section8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 149px;
}
#section8 .title {
  width: 713px;
  text-align: center;
  font-size: 72px;
  background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#section8 .description {
  margin-top: 17px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 40px;
}

#section8 .button1 {
  margin: 0 20px;
  border-radius: 12px;
  height: 120px;
  width: 640px;
  background-image: url(/public/img/home/button-pattern1.svg), linear-gradient(90deg, #283177 0%, #1F64A7 100%);
  background-size: 90px 95px, 100% 100%;
  background-position: left 550px top 25px, left 0 top 0;
  background-repeat: no-repeat, no-repeat;
  display: flex;
  align-items: center;
}
#section8 .button1>div {
  color: white;
  font-size: 24px;
  margin-left: 80px;
}

#section8 .button2 {
  margin: 0 20px;
  height: 120px;
  width: 640px;
  border-radius: 12px;
  border: 2px solid #283177;
  background: #FFF;
  background-image: url(/public/img/home/button-pattern2.svg);
  background-size: 90px 95px;
  background-position: left 550px top 25px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
#section8 .button2>div {
  color: #202751;
  font-size: 24px;
  margin-left: 80px;
}

#section8 .content {
  width: 1369px;
  margin-top: 50px;
  margin-bottom: 107px;
}
#section8 .content .item {
  margin-top: 40px;
  display: flex;
  color: rgba(0, 0, 0, 0.65);
  font-size: 30px;
}
#section8 .content .item .item-key {
  width: 147px;
  margin-right: 51px;
}

.contactus-map-section {
  height: 50vh;
  iframe {
    margin: 0 auto;
    width: 100%;
    height: 50vh;
    border: 0;
  }
}

#section9 {
  margin-top: 138px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section9 .description {
  color: rgba(0, 0, 0, 0.65);
  font-size: 40px;
}
#section9 form {
  margin-top: 90px;
  width: 664px;
}
#section9 form .label {
  color: rgba(0, 0, 0, 0.65);
  font-size: 22px;
}
#section9 form .inputbox {
  width: 100%;
}
#section9 form .inputbox input {
  width: 100%;
  border-radius: 12px;
  border: 2px solid rgba(0, 0, 0, 0.20);
  height: 65px;
}
#section9 form .inputbox textarea {
  width: 100%;
  border-radius: 12px;
  border: 2px solid rgba(0, 0, 0, 0.20);
  height: 178px;
}
#section9 form .button {
  border-radius: 12px;
  background-image: url(/public/img/contactus/info-icon.svg), linear-gradient(90deg, #1E60A3 0%, #283177 100%);
  background-position: right bottom, 0 0;
  background-repeat: no-repeat;
  width: 640px;
  height: 120px;
  display: flex;
  align-items: center;
  margin-bottom: 364px;
}
#section9 form .button .button-text {
  padding-left: 80px;
  color: #FFF;
  font-size: 24px;
}

/* waiting */
.waiting {
  margin-top: -96px;
  height: 100%;
  width: 100%;
  background-image: url(/public/img/waitinglist/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}
#section10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px; /* Added padding for responsiveness */
}
#section10 .title {
  margin-top: 289px;
  width: 870px;
  color: #0EC5FF;
  text-align: center;
  font-size: 76px;
}
#section10 .description {
  margin-top: 65px;
  color: #0EC5FF;
  font-family: Neuropol;
  font-size: 24px; /* Adjusted font size for better readability */
  text-align: center;
  width: 860px; /* Restrict the width for better formatting */
  line-height: 1.5; /* Added line-height for better readability */
}
#section10 .button {
  border-radius: 12px;
  background-image: url(/public/img/waitinglist/btn-bg.svg), linear-gradient(90deg, #FFF 0%, #CFE8FF 100%);
  background-position: right bottom, 0 0;
  background-repeat: no-repeat;
  width: 640px;
  height: 120px;
  display: flex;
  align-items: center;
  margin-top: 79px;
  cursor: pointer;
  user-select: none;
}
#section10 .button .button-text {
  color: #17538C;
  margin-left: 80px;
  font-size: 24px;
}
/* about us */
.aboutus {
  padding-top: 149px;
  padding-bottom: 327px;
  background-image: url(/public/img/aboutus/bg.svg), url(/public/img/aboutus/bg1.svg), url(/public/img/aboutus/bg2.svg);
  background-position: left top, left bottom, right bottom 448px;
  background-repeat: no-repeat;
}
#section11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1369px;
  margin-left: auto;
  margin-right: auto;
}
#section11 .title {
  background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 713px;
  text-align: center;
  font-size: 72px;
}
#section11 .description {
  margin-top: 61px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 40px;
}
#section11 .title1 {
  margin-top: 175px;
  margin-bottom: 28px;
  width: 527px;
  font-size: 72px;
  background: linear-gradient(90deg, #090909 0%, #636464 100%, #282D74 100.01%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#section11 .frame11 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#section11 .frame11 .image {
  width: 491px;
  height: 614px;
}
#section11 .frame11 .text {
  width: 644px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 30px;
}
#section11 .title2 {
  margin-top: 54px;
  color: #000;
  font-size: 72px;
}
#section11 .title3 {
  margin-top: 237px;
  color: #000;
  font-size: 72px;
}
#section11 .title4 {
  margin-top: 200px;
  color: #000;
  font-size: 72px;
}
#section11 .frame12 {
  margin-top: 117px;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 64px;
}

#section11 .frame12 .profile-card {
  width: 368px;
  height: 451px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 76px;
  position: relative;
  background-image: url(/public/img/aboutus/rect.png);
  background-position: -10px -30px;
  background-size: 380px 370px;
  background-repeat: no-repeat;
}

#section11 .frame12 .profile-card .profile-img {
  width: 232px;
  height: 232px;
  img {
    border-radius: 50%;
  }
}

#section11 .frame12 .profile-card .profile-name {
  margin-top: 12px;
  color: #000;
  font-family: Neuropol;
  font-size: 22px;
}

#section11 .frame12 .profile-card .profile-role {
  margin-top: 11px;
  height: 46px;
  width: 146px;
  text-align: center;
  color: #000;
  font-size: 18px;
}

#section11 .frame12 .profile-card .profile-description {
  margin-top: 15px;
  text-align: center;
  color: #000;
  font-size: 14px;
}

#section11 .frame12 .profile-card .profile-links {
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto auto auto;
  //column-gap: 20px;
  cursor: pointer;
  img {
    width: 31px;
    height: 31px;
  }
}

#section11 .frame13 {
  display: grid;
  column-gap: 78px;
  row-gap: 110px;
  grid-template-columns: auto auto auto auto;
  margin-top: 64px;
}

#section11 .frame13 .profile-card {
  width: 258px;
  height: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url(/public/img/aboutus/star-bg.png);
  background-size: 290px 335px;
  background-position: left -20px top -20px;
  background-repeat: no-repeat;
}

#section11 .frame13 .profile-card .profile-img {
  margin-top: 53px;
  width: 170px;
  height: 170px;
  background-color: #D9D9D9;
  border-radius: 100%;
  img {
    border-radius: 50%;
  }
}

#section11 .frame13 .profile-card .profile-name {
  margin-top: 36px;
  color: #000;
  font-family: Neuropol;
  font-size: 18px;
}

#section11 .frame13 .profile-card .profile-role {
  margin: 6px auto;
  text-align: center;
  color: #000;
  font-size: 14px;
}
/* add linkedIn icons */
#section11 .frame13 .profile-card .profile-links {
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto auto auto;
  //column-gap: 20px;
  cursor: pointer;
  img {
    width: 31px;
    height: 31px;
  }
}

#section11 .frame14 {
  margin-top: 48px;
  display: grid;
  column-gap: 78px;
  row-gap: 110px;
  grid-template-columns: auto auto auto auto;
}

#section11 .frame14 .profile-card {
  width: 258px;
  height: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url(/public/img/aboutus/star-bg.png);
  background-size: 290px 335px;
  background-position: left -20px top -20px;
  background-repeat: no-repeat;
}

#section11 .frame14 .profile-card .profile-img {
  margin-top: 53px;
  width: 170px;
  height: 170px;
  background-color: #D9D9D9;
  border-radius: 100%;
  img {
    border-radius: 50%;
  }
}

#section11 .frame14 .profile-card .profile-name {
  margin-top: 36px;
  color: #000;
  font-family: Neuropol;
  font-size: 18px;
}

#section11 .frame14 .profile-card .profile-role {
  margin-top: 6px;
  height: 36px;
  text-align: center;
  color: #000;
  font-size: 14px;
}

/* waitinglist form */
#section12 {
  //margin-top: 138px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section12 .description {
  color: white;
  font-size: 40px;
}
#section12 form {
  margin-top: 90px;
  height: 100vh;
  width: 664px;
}
#section12 form .label {
  color: white;
  font-size: 22px;
}
#section12 form .inputbox {
  width: 100%;
}
#section12 form .inputbox input {
  width: 100%;
  border-radius: 12px;
  border: 2px solid rgba(0, 0, 0, 0.20);
  height: 65px;
}
#section12 form .inputbox textarea {
  width: 100%;
  border-radius: 12px;
  border: 2px solid rgba(0, 0, 0, 0.20);
  height: 178px;
}
#section12 form .button {
  border-radius: 12px;
  background-image: url(/public/img/contactus/info-icon.svg), linear-gradient(90deg, #1E60A3 0%, #283177 100%);
  background-position: right bottom, 0 0;
  background-repeat: no-repeat;
  width: 640px;
  height: 120px;
  display: flex;
  align-items: center;
  //margin-bottom: 364px;
}
#section12 form .button .button-text {
  padding-left: 80px;
  color: #FFF;
  font-size: 24px;
}

/* footer */
footer {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -110px;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

footer .opacitybg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

.footer-description {
  font-size: 16px;
  font-weight: 400;
  color: white;
  line-height: 19px;
}
.footer-description>span {
  color: #63DAFF;
}

.footer-copyright {
  font-size: 16px;
  font-weight: 400;
  color: white;
  line-height: 19px;
}
.tablet {
  display: none;
}
.mobile {
  display: none;
}

.menulist {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: none;
}
.close-tap {
  padding-top: 60px;
  display: flex;
}
.close-button {
  margin-left: auto;
  margin-right: 50px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.nav-menu {
  width: 100vw; 
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1440px) {
  .menulist {
    display: block;
  }
  .menulist .header-nav {
    font-size: 24px;
  }
  .menulist .header-nav .login_button {
    font-size: 28px;
    color: red;
    animation: coloring 1.5s infinite;
  }
  @keyframes coloring {
    0% {
      color: red;
    }
    25% {
      color: orange;
    }
    50% {
      color: yellow;
    }
    75% {
      color: green;
    }
    100% {
      color: blue;
    }
  }

  .desktop {
    display: none;
  }
  .tablet {
    display: block;
  }
  /* header */
  .header-logo {
    margin-left: 25px;
  }

  /* home */
  .frame1 {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .frame1 .title {
    font-size: 18px;
  }
  .frame1 .main-title {
    font-size: 54px;
    margin-top: 8px;
  }
  .frame1 .content {
    font-size: 24px;
  }
  .frame1 .button1 {
    height: 106px;
    width: 100%;
    margin-top: 16px;
  }
  .frame1 .button1>div {
    margin-left: 60px;
  }
  #section2 {
    margin-top: 40px;
    padding-bottom: 75px;
  }
  .frame2 {
    width: 100%;
    padding-right: 0;
  }
  .frame2 .title {
    font-size: 18px;
  }
  .frame2 .main-title {
    font-size: 54px;
    margin-top: 8px;
  }
  .frame2 .content {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    margin-top: 46px;
  }
  .frame2 .content .content-text {
    font-size: 24px;
    width: 100%;
  }
  .frame3 {
    width: 100%;
    padding: 100px 40px;
  }
  .frame3 .title {
    font-size: 30px;
    color: white;
  }
  .frame3 .main-title {
    font-size: 72px;
    color: white;
  }
  #section3 .group-text {
    font-size: 24px;
    width: 100%;
    max-width: 540px;
    padding: 0 10px;
    margin-top: 18px;
  }
  #section4 {
    height: auto;
    padding: 100px 40px;
  }
  #section4 .frame4 {
    width: 100%;
  }
  .frame4 .title {
    font-size: 18px;
  }
  .frame4 .main-title {
    font-size: 54px;
    margin-top: 12px;
    margin-bottom: 60px;
  }
  .frame4 .text-group {
    width: 50%;
    font-size: 24px;
    /* padding-left: 40px; */
  }
  .frame4 .img {
    width: 45%;
  }
  .frame4 .button1 {
    height: 106px;
    width: 100%;
    margin-top: 16px;
  }
  .frame4 .button1>div {
    margin-left: 60px;
  }
  .frame4 .button2 {
    height: 106px;
    width: 100%;
    margin-top: 10px;
  }
  .frame4 .button2>div {
    margin-left: 60px;
  }

  /* features */
  
  #section5 .main-title {
    padding-top: 88px;
    padding-bottom: 117px;
    margin-left: auto;
    margin-right: auto;
    width: 696px;
    font-size: 54px;
    text-align: center;
    background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #section5 .content {
    width: calc(100% - 70px);
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0px;
  }
  #section5 .content .description {
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
    width: 55%;
  }
  #section5 .content .img {
    margin-left: auto;
    margin-right: auto;
    width: 309px;
    height: 448px;
  }

  #section6 {
    background-image: url(/public/img/features/bg.svg);
    background-position: top 0px center;
    background-repeat: no-repeat;
    height: auto;
    margin-top: 216px;
    padding-top: 58px;
  }

  .frame6 {
    width: calc(100% - 60px);
    padding-right: 0px;
    position: relative;
  }
  .frame6 .content {
    width: 100%;
    height: auto;
    padding: 45px 50px;
    border-radius: 15px;
    background-color: white;
    background-image: url(/public/img/features/bg1.svg);
    background-position: top -50px right;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  }
  .frame6 .content .title {
    color: rgba(0, 0, 0, 0.65);
    font-size: 30px;
  }
  .frame6 .content .text {
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
  }
  .frame6 .number1 {
    font-size: 180px;
    width: 66px;
    background: rgba(0, 0, 0, 0.65);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: -110px;
    left: 50%;
  }
  .frame7 {
    width: calc(100% - 60px);
    margin-top: 137px;
    padding-left: 0px;
    position: relative;
  }
  .frame7 .content {
    border-radius: 15px;
    background-image: url(/public/img/features/bg2.svg), linear-gradient(180deg, #034993 0%, #010C6E 100%);
    background-size: auto;
    background-position: top center, 0 0;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding: 69px 46px 78px 54px;
  }
  .frame7 .content .title {
    color: rgba(255, 255, 255, 0.80);
    font-size: 30px;
  }
  .frame7 .content .text {
    color: rgba(255, 255, 255, 0.80);
    font-size: 24px;
    margin-top: 26px;
  }
  .frame7 .number2 {
    width: fit-content;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 51%, rgba(255, 255, 255, 0.70) 79%, rgba(255, 255, 255, 0.70) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: unset;
    font-size: 180px;
    position: absolute;
    top: -130px;
    left: calc(50% - 60px);
  }

  .frame8 {
    width: calc(100% - 60px);
    margin-top: 200px;
    padding-right: 0px;
    position: relative;
  }
  .frame8 .content {
    width: 100%;
    height: auto;
    padding: 79px 49px 71px 51px;
    border-radius: 15px;
    background-color: white;
    background-image: url(/public/img/features/bg3.svg);
    background-position: bottom -80px right -40px;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  }
  .frame8 .content .title {
    color: rgba(0, 0, 0, 0.65);
    font-size: 30px;
  }
  .frame8 .content .text {
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
  }
  .frame8 .number3 {
    font-size: 180px;
    width: fit-content;
    background: rgba(0, 0, 0, 0.65);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: -125px;
    left: calc(50% - 60px);
  }

  .frame9 {
    width: calc(100% - 60px);
    margin-top: 174px;
    padding-left: 0px;
    position: relative;
  }
  .frame9 .content {
    border-radius: 15px;
    background-image: url(/public/img/features/bg4.svg), linear-gradient(180deg, #034993 0%, #010C6E 100%);
    background-size: auto;
    background-position: bottom -40px center, 0 0;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding: 45px 46px 54px 56px;
  }
  .frame9 .content .title {
    color: rgba(255, 255, 255, 0.80);
    font-size: 30px;
  }
  .frame9 .content .text {
    color: rgba(255, 255, 255, 0.80);
    font-size: 24px;
    margin-top: 17px;
  }
  .frame9 .number4 {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 51%, rgba(255, 255, 255, 0.70) 79%, rgba(255, 255, 255, 0.70) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 180px;
    position: absolute;
    top: -130px;
    left: calc(50% - 60px);
  }
  .bottom-title {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 127px;
    margin-bottom: 198px;
    background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 35px;
    text-align: center;
  }
  /* industry */
  #section7 {
    padding-top: 142px;
  }
  #section7 .title {
    width: 713px;
    font-size: 54px;
  }
  
  #section7 .description {
    color: rgba(0, 0, 0, 0.65);
    font-size: 30px;
    margin-top: 18px;
    margin-left: 60px;
    margin-right: 40px;
  }

  #section7 .card-group {
    margin-top: 38px;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: auto auto;
    margin-bottom: 99px;
  }
  #section7 .card-group .card {
    border-radius: 27px;
    background: linear-gradient(180deg, #034993 0%, #010C6E 100%);
    width: 373px;
    height: 174px;
    position: relative;
    padding: 25px 25px 25px 30px;
  }
  #section7 .card-group .card .open-quote {
    position: absolute;
    top: 17px;
    left: 35px;
    width: 22px;
    height: 14px;
  }
  #section7 .card-group .card .close-quote {
    position: absolute;
    top: 17px;
    right: 35px;
    width: 22px;
    height: 14px;
  }
  #section7 .card-group .card .text {
    font-size: 16px;
    color: #FFF;
    height: 67px;
    display: flex;
    align-items: center;
    padding-left: 22px;
  }
  #section7 .card-group .card .user {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #section7 .card-group .card .user .avatar {
    width: 58px;
    height: 58px;
    margin-right: 9px;
  }
  
  #section7 .card-group .card .user .intro {
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    line-height: 127.778%;
    color: #FFF;
    width: 248px;
    min-width: auto;
  }

  #section7 .video {
    margin-top: 30px;
    width: calc(100% - 60px);
    height: auto;
    margin-left: 30px;
    margin-right: 30px;
  }
  
  #section7 .content {
    padding-top: 50px;
    width: calc(100% - 70px);
    height: 370px;
    background-image: url(/public/img/industry/tablet-tree.svg);
    background-size: 657px;
    background-position: center;
    background-repeat: no-repeat;
  }
  #section7 .content .text1 {
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    font-size: 24px;
  }
  #section7 .content .text2 {
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    font-size: 30px;
  }

  /* contactus */
  .contactus {
    background-image: url(/public/img/contactus/tablet-tree1.svg), url(/public/img/contactus/tree2.svg);
    background-position: left top, bottom right;
    background-repeat: no-repeat;
  }
  #section8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }
  #section8 .title {
    width: 523px;
    text-align: center;
    font-size: 54px;
    background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  #section8 .description {
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    font-size: 30px;
  }

  #section8 .button1 {
    height: 106px;
    width: 100%;
    margin-top: 16px;
  }
  #section8 .button1>div {
    margin-left: 60px;
  }
  #section8 .button2 {
    height: 106px;
    width: 100%;
    margin-top: 10px;
  }
  #section8 .button2>div {
    margin-left: 60px;
  }

  #section8 .content {
    width: calc(100vw - 90px);
    margin-left: 45px;
    margin-top: 50px;
    margin-bottom: 39px;
  }
  #section8 .content .item {
    margin-top: 0px;
    margin-bottom: 30px;
    display: flex;
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
  }
  #section8 .content .item .item-key {
    width: 147px;
    margin-right: 51px;
  }
  #section8 .content .item .item-value {
    width: calc(100vw - 290px);
    margin-right: 0px;
  }

  #section9 {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #section9 .description {
    color: rgba(0, 0, 0, 0.65);
    font-size: 40px;
  }
  #section9 form {
    margin-top: 142px;
    width: 664px;
  }
  #section9 form .label {
    color: rgba(0, 0, 0, 0.65);
    font-size: 22px;
  }
  #section9 form .inputbox {
    width: 100%;
  }
  #section9 form .inputbox input {
    width: 100%;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.20);
    height: 65px;
  }
  #section9 form .inputbox textarea {
    width: 100%;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.20);
    height: 178px;
  }
  #section9 form .button {
    border-radius: 12px;
    background-image: url(/public/img/contactus/info-icon.svg), linear-gradient(90deg, #1E60A3 0%, #283177 100%);
    background-size: 61px 67px, 100% 100%;
    background-position: right bottom, 0 0;
    background-repeat: no-repeat;
    width: 375px;
    height: 106px;
    display: flex;
    align-items: center;
    margin-bottom: 207px;
  }
  #section9 form .button .button-text {
    padding-left: 25px;
    color: #FFF;
    font-size: 24px;
  }
  /* waiting */
  .waiting {
    height: 100%;
  }
  #section10 .title {
    margin-top: 150px;
    width: 90%;
    font-size: 40px;
  }
  #section10 .description {
    margin-top: 100px;
    font-size: 20px;
    max-width: 720px;
  }
  #section10 .button {
    width: 250px;
    height: 80px;
    margin-top: 30px;
    background-image: url(/public/img/waitinglist/tablet-btn-bg.svg), linear-gradient(90deg, #FFF 0%, #CDE6FF 100%);
  }
  #section10 .button .button-text {
    margin-left: 10px;
    font-size: 18px;
  }

  /* aboutus */
  .aboutus {
    padding-top: 40px;
  }
  #section11 {
    width: 100%;
  }
  #section11 .title {
    width: 100%;
    text-align: center;
  }
  #section11 .description {
    padding: 0 40px;
    margin-top: 31px;
    font-size: 30px;
    text-align: center;
  }
  #section11 .description1 {
    padding: 0 40px;
    margin-top: 61px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
    text-align: center;
  }
  #section11 .title1 {
    width: 100%;
    margin-top: 30px;
    padding: 0 60px;;
    text-align: end;
    font-size: 72px;
  }
  #section11 .frame11 {
    align-items: flex-start;
  }
  #section11 .frame11 .image {
    max-width: 375px;
    width: 100%;
    height: fit-content;
    padding-right: 40px;
  }
  #section11 .frame11 .text {
    width: 100%;
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
    padding: 0 40px;
  }
  #section11 .title2 {
    margin-top: 94px;
    font-size: 54px;
  }
  #section11 .frame12 {
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 40px;
  }
  #section11 .frame12 .profile-card {
    width: 45%;
    height: auto;
    padding-top: 16px;
  }
  #section11 .title3 {
    margin-top: 167px;
    font-size: 54px;
  }
  #section11 .frame13 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 64px;
  }
  #section11 .frame13 .profile-card {
    width: 25%;
    height: auto;
  }
  #section11 .title4 {
    margin-top: 200px;
    font-size: 54px;
  }
  #section11 .frame14 {
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #section11 .frame14 .profile-card {
    width: 27%;
    height: auto;
  }

  /* waitinglist form */
  #section12 {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #section12 .description {
    color: white;
    font-size: 40px;
  }
  #section12 form {
    margin-top: 142px;
    width: 664px;
  }
  #section12 form .label {
    color: white;
    font-size: 22px;
  }
  #section12 form .inputbox {
    width: 100%;
  }
  #section12 form .inputbox input {
    width: 100%;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.20);
    height: 65px;
  }
  #section12 form .inputbox textarea {
    width: 100%;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.20);
    height: 178px;
  }
  #section12 form .button {
    border-radius: 12px;
    background-image: url(/public/img/contactus/info-icon.svg), linear-gradient(90deg, #1E60A3 0%, #283177 100%);
    background-size: 61px 67px, 100% 100%;
    background-position: right bottom, 0 0;
    background-repeat: no-repeat;
    width: 375px;
    height: 106px;
    display: flex;
    align-items: center;
    //margin-bottom: 207px;
  }
  #section12 form .button .button-text {
    padding-left: 25px;
    color: #FFF;
    font-size: 24px;
  }
  
  /* footer */
  footer {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .footer-description {
    font-family: 'Reem kufi';
  }
  .footer-copyright {
    margin-top: 13px;
    font-family: 'Reem kufi';
  }
}

/* mobile */
@media only screen and (max-width: 835px) {
  .menulist {
    display: block;
  }
  .menulist .header-nav {
    font-size: 18px;
  }
  .menulist .header-nav .login_button {
    font-size: 20px;
    color: red;
    animation: coloring 1.5s infinite;
  }
  @keyframes coloring {
    0% {
      color: red;
    }
    25% {
      color: orange;
    }
    50% {
      color: yellow;
    }
    75% {
      color: green;
    }
    100% {
      color: blue;
    }
  }
  .menulist .close-tap {
    padding-top: 30px;
  }
  .menulist .close-button {
    margin-right: 30px;
  }
  .menulist .nav-menu {
    height: calc(100vh - 120px);
  }
  .desktop {
    display: none;
  }
  .tablet {
    display: none;
  }
  .mobile {
    display: block;
  }
  /* header */
  .header-logo {
    margin-left: 20px;
  }

  /* home */
  #section1 {
    height: auto;
    flex-direction: column;
  }
  .frame1 .title {
    font-size: 14px;
  }
  .frame1 .main-title {
    font-size: 46px;
    margin-top: 4px;
  }
  .frame1 .content {
    font-size: 16px;
    margin-top: 30px;
  }
  .frame1 .button1 {
    height: 106px;
    width: 100%;
    margin-top: 16px;
  }
  .frame1 .button1>div {
    margin-left: 60px;
  }
  #section2 {
    margin-top: 60px;
    padding-bottom: 45px;
  }
  .frame2 {
    padding: 0 20px;
  }
  .frame2 .title {
    font-size: 14px;
  }
  .frame2 .main-title {
    font-size: 46px;
    margin-top: 4px;
  }
  .frame2 .content {
    display: block;
    margin-top: 26px;
  }
  .frame2 .content .content-text {
    font-size: 16px;
    width: 100%;
  }
  .frame2 .content img {
    width: 70%;
    margin: auto;
  }
  #section3 {
    height: auto;
  }
  .frame3 {
    width: 100%;
    padding: 60px 20px;
  }
  .frame3 .title {
    font-size: 14px;
  }
  .frame3 .main-title {
    font-size: 46px;
  }
  #section3 .group-text {
    font-size: 16px;
    max-width: 240px;
  }
  #section4 {
    height: auto;
    padding: 80px 20px;
  }
  .frame4 .title {
    font-size: 14px;
  }
  .frame4 .main-title {
    font-size: 46px;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .frame4 .text-group {
    width: 100%;
    font-size: 16px;
  }
  .frame4 .img {
    width: 80%;
  }
  .frame4 .button1 {
    height: 106px;
    width: 100%;
    margin-top: 16px;
  }
  .frame4 .button1>div {
    margin-left: 60px;
  }
  .frame4 .button2 {
    height: 106px;
    width: 100%;
    margin-top: 10px;
  }
  .frame4 .button2>div {
    margin-left: 60px;
  }

  /* News */
  #sectionNews .main-title {
    padding-top: 78px;
    padding-bottom: 36px;
  }

  /* features */
  #section5 {
    background-position: left -170px top -30px;
  }
  #section5 .main-title {
    padding-top: 78px;
    padding-bottom: 36px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 36px);
    font-size: 40px;
    text-align: center;
    background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #section5 .content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 24px;
    padding-left: 30px;
  }
  #section5 .content .description {
    margin-top: 52px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    width: 100%;
  }
  #section5 .content .img {
    width: 233px;
    height: 262px;
  }

  #section6 {
    background-image: url(/public/img/features/bg.svg);
    background-position: top 0px right 0px;
    background-size: 200%;
    background-repeat: no-repeat;
    height: auto;
    margin-top: -50px;
    padding-top: 212px;
  }

  .frame6 {
    width: calc(100% - 30px);
    padding-right: 0px;
    position: relative;
  }
  .frame6 .content {
    width: 100%;
    height: auto;
    padding: 62px 52px 58px 50px;
    border-radius: 15px;
    background-color: white;
    background-image: url(/public/img/features/bg1.svg);
    background-position: top -50px right;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  }
  .frame6 .content .title {
    color: rgba(0, 0, 0, 0.65);
    font-size: 30px;
  }
  .frame6 .content .text {
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
  }
  .frame6 .number1 {
    font-size: 150px;
    width: 33px;
    background: rgba(0, 0, 0, 0.65);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: -110px;
    left: 50%;
  }
  .frame7 {
    width: calc(100% - 30px);
    margin-top: 137px;
    padding-left: 0px;
    position: relative;
  }
  .frame7 .content {
    border-radius: 15px;
    background-image: url(/public/img/features/bg2.svg), linear-gradient(180deg, #034993 0%, #010C6E 100%);
    background-size: 20%, auto;
    background-position: top center, 0 0;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding: 92px 54px 80px 52px;
  }
  .frame7 .content .title {
    color: rgba(255, 255, 255, 0.80);
    font-size: 30px;
  }
  .frame7 .content .text {
    color: rgba(255, 255, 255, 0.80);
    font-size: 16px;
    margin-top: 30px;
  }
  .frame7 .number2 {
    width: fit-content;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 51%, rgba(255, 255, 255, 0.70) 79%, rgba(255, 255, 255, 0.70) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: unset;
    font-size: 150px;
    position: absolute;
    top: -115px;
    left: calc(50% - 60px);
  }

  .frame8 {
    width: calc(100% - 30px);
    margin-top: 130px;
    padding-right: 0px;
    position: relative;
  }
  .frame8 .content {
    width: 100%;
    height: auto;
    padding: 62px 55px 52px 50px;
    border-radius: 15px;
    background-color: white;
    background-image: url(/public/img/features/bg3.svg);
    background-position: bottom -80px right -40px;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  }
  .frame8 .content .title {
    color: rgba(0, 0, 0, 0.65);
    font-size: 30px;
  }
  .frame8 .content .text {
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
  }
  .frame8 .number3 {
    font-size: 150px;
    width: fit-content;
    background: rgba(0, 0, 0, 0.65);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: -110px;
    left: calc(50% - 60px);
  }

  .frame9 {
    width: calc(100% - 30px);
    margin-top: 126px;
    padding-left: 0px;
    position: relative;
  }
  .frame9 .content {
    border-radius: 15px;
    background-image: url(/public/img/features/bg4.svg), linear-gradient(180deg, #034993 0%, #010C6E 100%);
    background-size: 20%, auto;
    background-position: bottom -10px center, 0 0;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding: 94px 53px 70px 52px;
  }
  .frame9 .content .title {
    color: rgba(255, 255, 255, 0.80);
    font-size: 30px;
  }
  .frame9 .content .text {
    color: rgba(255, 255, 255, 0.80);
    font-size: 16px;
    margin-top: 30px;
  }
  .frame9 .number4 {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 51%, rgba(255, 255, 255, 0.70) 79%, rgba(255, 255, 255, 0.70) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 150px;
    position: absolute;
    top: -110px;
    left: calc(50% - 60px);
  }
  .bottom-title {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 105px;
    margin-bottom: 186px;
    background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    text-align: center;
  }

  /* industry */
  #section7 {
    padding-top: 53px;
    overflow: hidden;
  }
  #section7 .title {
    width: 405px;
    font-size: 46px;
  }
  #section7 .description {
    font-size: 24px;
    //width: calc(100% - 24px);
    padding-left: 10px;
  }
  #section7 .card-group {
    margin-top: 73px;
    padding-left: 20px;
    display: flex;
    width: 100vw;
    overflow-x: scroll;
    scrollbar-width: 0px;
    column-gap: 10px;
    margin-bottom: 80px;
  }
  #section7 .card-group::-webkit-scrollbar {
    display: none;
  }
  #section7 .card-group .card {
    border-radius: 11px;
    background: linear-gradient(180deg, #034993 0%, #010C6E 100%);
    width: 373px;
    height: 174px;
    position: relative;
    padding: 25px 25px 25px 30px;
  }
  #section7 .card-group .card .open-quote {
    position: absolute;
    top: 17px;
    left: 35px;
    width: 23px;
    height: 15px;
  }
  #section7 .card-group .card .close-quote {
    position: absolute;
    top: 17px;
    right: 35px;
    width: 23px;
    height: 15px;
  }
  #section7 .card-group .card .text {
    font-size: 16px;
    height: 67px;
  }
  #section7 .card-group .card .user {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #section7 .card-group .card .user .avatar {
    width: 58px;
    height: 58px;
    margin-right: 12px;
  }
  #section7 .card-group .card .user .intro {
    min-width: auto;
    width: 248px;
  }
  #section7 .video {
    margin-top: 23px;
    width: calc(100% - 20px);
    height: auto;
  }
  #section7 .content {
    //padding-top: 80px;
    width: calc(100vw - 30px);
    //height: 390px;
    height: 220px;
    background-image: url(/public/img/industry/mobile-tree.svg);
    //background-size: 287px;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  #section7 .content .text1 {
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    font-size: 16px;
  }
  #section7 .content .text2 {
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    font-size: 24px;
  }

  #section7 .company {
    height: 560px;
  }
  #section7 .company .company-logos {
    margin: 0 auto;
    display: block;
  }
  #section7 .company .company-logos img {
    margin: 20px auto;
  }

  /* contactus */
  .contactus {
    background-image: url(/public/img/contactus/tablet-tree1.svg), url(/public/img/contactus/mobile-tree2.svg);
    background-position: left top, bottom right;
    background-repeat: no-repeat;
  }
  #section8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
  }
  #section8 .title {
    width: calc(100% - 120px);
    text-align: center;
    font-size: 46px;
    background: linear-gradient(90deg, #1B5A9C 0%, #282D74 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  #section8 .description {
    margin-top: 24px;
    width: 100%;
    padding-left: 15px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    font-size: 24px;
  }

  #section8 .button1 {
    height: 106px;
    width: 100%;
    margin: 16px auto 0 auto;
  }
  #section8 .button1>div {
    margin-left: 60px;
  }
  #section8 .button2 {
    height: 106px;
    width: 100%;
    margin: 16px auto 0 auto;
  }
  #section8 .button2>div {
    //margin-left: 30px;
    margin: 0 15px;
  }

  #section8 .content {
    width: 100%;
    padding-left: 15px;
    margin-left: 0px;

    margin-top: 36px;
    margin-bottom: 47px;
  }
  #section8 .content .item {
    margin-top: 0px;
    margin-bottom: 30px;
    display: flex;
    color: rgba(0, 0, 0, 0.65);
    font-size: 18px;
  }
  #section8 .content .item .item-key {
    width: 137px;
    margin-right: 0px;
  }
  #section8 .content .item .item-value {
    width: calc(100% - 100px);
    margin-right: 0px;
  }
  
  #section9 {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #section9 .description {
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
  }
  #section9 form {
    margin-top: 75px;
    width: calc(100% - 30px);
  }
  #section9 form > div {
    margin-top: 16px;
  }
  #section9 form .label {
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    margin-bottom: 7px;
  }
  #section9 form .inputbox {
    width: 100%;
  }
  #section9 form .inputbox input {
    width: 100%;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.20);
    height: 51px;
  }
  #section9 form .inputbox textarea {
    width: 100%;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.20);
    height: 178px;
  }
  #section9 form .button {
    margin-top: 11px;
    border-radius: 12px;
    background-image: url(/public/img/contactus/info-icon.svg), linear-gradient(90deg, #1E60A3 0%, #283177 100%);
    background-size: 61px 67px, 100% 100%;
    background-position: right bottom, 0 0;
    background-repeat: no-repeat;
    width: 375px;
    height: 106px;
    display: flex;
    align-items: center;
    margin-bottom: 221px;
  }
  #section9 form .button .button-text {
    padding-left: 25px;
    color: #FFF;
    font-size: 24px;
  }

  /* waiting */
  .waiting {
    height: 100%;
    height: fit-content;
    margin-top: -79px;
  }
  #section10 .title {
    margin-top: 165px;
    width: calc(100% - 30px);
    font-size: 46px;
  }
  #section10 .description {
    margin-top: 47px;
    font-size: 18px;
    //font-family: 'Reem Kufi';
    max-width: 350px;
  }
  #section10 .button {
    width: 375px;
    height: 106px;
    margin-top: 75px;
    background-image: url(/public/img/waitinglist/tablet-btn-bg.svg), linear-gradient(90deg, #FFF 0%, #CDE6FF 100%);
  }
  #section10 .button .button-text {
    margin-left: 21px;
    font-size: 20px;
  }
  #section10 .button {
    margin-bottom: 180px;
  }

  /* aboutus */
  .aboutus {
    padding-top: 40px;
    padding-bottom: 180px;
    background-image: none;
  }
  #section11 {
    width: 100%;
  }
  #section11 .title {
    width: 100%;
    text-align: center;
  }
  #section11 .description {
    padding: 0 40px;
    margin-top: 31px;
    font-size: 30px;
    text-align: center;
  }
  #section11 .description1 {
    padding: 0 40px;
    margin-top: 61px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
    text-align: center;
  }
  #section11 .title1 {
    background: linear-gradient(90deg, #282D74 0%, #273077 100%, #1C5699 100%);
    background-clip: text;
    -webkit-background-clip: text;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 46px;
  }
  #section11 .frame11 {
    flex-direction: column;
    align-items: center;
  }
  #section11 .frame11 .image {
    max-width: 249px;
    width: 100%;
    height: fit-content;
    padding-right: 0px;
    margin-top: 28px;
  }
  #section11 .frame11 .text {
    width: 100%;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    padding: 0 20px;
  }
  #section11 .title2 {
    width: 100%;
    margin-top: 74px;
    font-size: 46px;
    padding: 0 20px;
  }
  #section11 .frame12 {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 40px;
  }
  #section11 .frame12 .profile-card {
    width: 100%;
    height: auto;
    padding-top: 46px;
  }
  #section11 .frame12 .profile-card .profile-img {
    width: 215px;
    height: 215px;
  }
  #section11 .frame12 .profile-card .profile-name {
    margin-top: 12px;
    font-size: 18px;
  }
  #section11 .frame12 .profile-card .profile-role {
    height: auto;
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
  }
  #section11 .frame12 .profile-card .profile-links {
    margin-top: 16px;
    //column-gap: 20px;
  }
  #section11 .frame12 .profile-card .profile-links .profile-link{
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  #section11 .title3 {
    margin-top: 97px;
    font-size: 46px;
    text-align: center;
  }
  #section11 .frame13 {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    row-gap: 40px;
    margin-top: 34px;
    padding: 0 20px;
  }
  #section11 .frame13 .profile-card {
    width: 100%;
    height: 275px;
    margin: auto;
    background-size: contain;
  }
  #section11 .frame13 .profile-card .profile-img {
    margin-top: 23px;
    width: 139px;
    height: 139px;
  }
  #section11 .frame13 .profile-card .profile-name {
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
  }
  #section11 .frame13 .profile-card .profile-role {
    margin-top: 4px;
    font-size: 12px;
  }
  #section11 .title4 {
    margin-top: 140px;
    font-size: 46px;
    padding: 0 20px;
    text-align: center;
  }
  #section11 .frame14 {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    row-gap: 40px;
    margin-top: 34px;
    padding: 0 20px;
  }
  #section11 .frame14 .profile-card {
    width: 100%;
    height: 275px;
    margin: auto;
    background-size: contain;
  }
  #section11 .frame14 .profile-card .profile-img {
    margin-top: 23px;
    width: 131px;
    height: 131px;
    border-radius: 100%;
  }
  #section11 .frame14 .profile-card .profile-name {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
  }
  #section11 .frame14 .profile-card .profile-role {
    margin-top: 4px;
    font-size: 12px;
  }

  /* waitinglist form */
  #section12 {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #section12 .description {
    color: rgba(0, 0, 0, 0.65);
    font-size: 24px;
  }
  #section12 form {
    height: 85vh;
    margin-top: 75px;
    width: calc(100% - 30px);
  }
  #section12 form > div {
    margin-top: 16px;
  }
  #section12 form .label {
    color: white;
    font-size: 16px;
    margin-bottom: 7px;
  }
  #section12 form .inputbox {
    width: 100%;
  }
  #section12 form .inputbox input {
    width: 100%;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.20);
    height: 51px;
  }
  #section12 form .inputbox textarea {
    width: 100%;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.20);
    height: 178px;
  }
  #section12 form .button {
    margin-top: 11px;
    border-radius: 12px;
    background-image: url(/public/img/contactus/info-icon.svg), linear-gradient(90deg, #1E60A3 0%, #283177 100%);
    background-size: 61px 67px, 100% 100%;
    background-position: right bottom, 0 0;
    background-repeat: no-repeat;
    width: 375px;
    height: 106px;
    display: flex;
    align-items: center;
    //margin-bottom: 221px;
  }
  #section12 form .button .button-text {
    padding-left: 25px;
    color: #FFF;
    font-size: 24px;
  }

  /* footer */
  footer {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  footer .opacitybg {
    background: rgba(0, 0, 0, 0.50);
  }
  .footer-description {
    font-family: 'Reem kufi';
  }
  .footer-copyright {
    margin-top: 13px;
    font-family: 'Reem kufi';
  }
}

